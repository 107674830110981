import { faChevronUp, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import {
  Button, Collapse,
  Container,
  Dropdown,
  Image,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Offcanvas,
} from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo.svg';
import './Header.scss';

interface HeaderProps {
  hideScheduleCall: boolean;
}


export const Header: React.FC<HeaderProps> = ({hideScheduleCall}) => {

  const navigate = useNavigate();
  const location = useLocation();

  const [showOptions, toggleOptions] = useState(false);

  const handleUseCasesClick = () => {
    if (location.pathname === '/') {
      // Scroll to the "use-cases" section if already on the home page
      const useCasesElement = document.getElementById('use-cases');
      if (useCasesElement) {
        useCasesElement.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      }
    } else {
      // Navigate to the home page with the hash
      navigate('/#use-cases');
    }
    toggleOptions(false)
  };

  return (
    <Navbar expand="sm" className={ 'p-2 pt-3 header-fonts' } sticky={'top'} bg={'white'}>
      <Container fluid={ 'sm' } className={ 'ms-2 me-2 p-0 max-width-100 align-items-center' }>
        <Navbar.Brand className={ 'mr-5 ms-1' } href={'/'}>
          <Image src={ Logo } width={ 100 }/>
        </Navbar.Brand>
        <Navbar.Toggle>
          <FontAwesomeIcon icon={ faEllipsis } />
        </Navbar.Toggle>
        {!location.pathname.includes('unsubscribe') && (
          <Navbar.Offcanvas placement={'start'}>
            <Offcanvas.Header closeButton className={'justify-content-end'} />
            <Offcanvas.Body className={'justify-content-end'}>
              <Nav>
                <Nav.Link className={'nav-item mb-md-0 mb-3'} onClick={() => handleUseCasesClick()}>
                  <span
                    className={`hover-underline-animation ${location.pathname.includes('use-cases') ? 'active-link' : ''}`}
                  >
                    Use Cases
                  </span>
                </Nav.Link>
                <Nav.Link href="/about" className={'nav-item mb-md-0 mb-3'} onClick={() => toggleOptions(false)}>
                  <span
                    className={`hover-underline-animation ${location.pathname.includes('about') ? 'active-link' : ''}`}
                  >
                    About
                  </span>
                </Nav.Link>
              </Nav>
              {(location.pathname.includes('about') || !hideScheduleCall) && (
                <Nav className={'me-3'}>
                  <Button variant="primary" className="btn-block slide-left-right" id="schedule-call-1"
                          onClick={ () => navigate('/contact') }>
                    <strong>Schedule a call</strong>
                  </Button>
                </Nav>
              )}
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        )}
      </Container>
    </Navbar>
  );
};
