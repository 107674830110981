import React, { useEffect, useRef, useState } from 'react';
import './HomeScreen.scss';
import { Button, Col, Container, Form, Image, InputGroup, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../components/Header/Header';
import { Subscribe as SubscribeModel } from '../../models/Subscribe';
import SubscribeService from '../../services/SubscribeService';
import { kpi } from '../../utils/images';

export const HomeScreen: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [showSuccess, toggleShowSuccess] = useState(false);
  const [showError, toggleShowError] = useState(false);
  const [validated, setValidated] = useState(false);

  const [hideScheduleCall, setHideScheduleCall] = useState(false);

  const scheduleCallRef = useRef<HTMLDivElement | null>(null);

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const steps = document.querySelectorAll('.vertical-list-item');
      let newActiveStep = 0;

      steps.forEach((step, index) => {
        const rect = step.getBoundingClientRect();
        if (rect.top <= window.innerHeight / 2) {
          newActiveStep = index;
        }
      });

      setActiveStep(newActiveStep);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const items = [
    'Create an accurate, culture aligned A-grade persona to guide future-hire fit',
    'Accelerate time-to-impact for senior-level hires who need rapid, in-depth insights into their teams',
    'Identify the most impactful L&D needs as they evolve',
    'Identify critical employees who are operational linchpins',
    'Identify productivity gaps and turnover risks early',
    'And more...',
  ];

  useEffect(() => {
    if (window.location.hash === '#use-cases') {
      const useCasesElement = document.getElementById('use-cases');
      if (useCasesElement) {
        useCasesElement.scrollIntoView({ behavior: 'smooth' });
        window.history.replaceState(null, '', window.location.pathname);
      }
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const isVisible = entries[0].isIntersecting;
        setHideScheduleCall(isVisible);
      },
      {threshold: 0.1},
    );

    if (scheduleCallRef.current) {
      observer.observe(scheduleCallRef.current);
    }

    return () => {
      if (scheduleCallRef.current) {
        observer.unobserve(scheduleCallRef.current);
      }
    };
  }, [scheduleCallRef]);


  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity() === true) {
      await subscribeToUpdates();
    }
  }

  const subscribeToUpdates = async () => {
    try {
      const subscription: SubscribeModel = {
        email,
      };
      await SubscribeService.saveSubscription(subscription);
      toggleShowSuccess(true);
      setTimeout(() => {
        toggleShowSuccess(false);
      }, 4000);

      setEmail('');
      setValidated(false);
    } catch (error) {
      toggleShowError(true);
      setTimeout(() => {
        toggleShowError(false);
      }, 4000);
    }
  }

  return (
    <section>
      <Header hideScheduleCall={ hideScheduleCall }/>
      <section>
        <Container className={ 'main-container pb-0' }>
          <Row>
            <Col md={ 12 } className={ 'text-start' }>
              <div style={ {fontSize: '48px'} }>
                <strong>Make better <span className={ 'font-primary' }>business</span> decisions faster</strong>
              </div>
            </Col>
            <Col md={ 5 } sm={ 12 } className={ 'pt-5' }>
              <Row className={ 'text-start' }>
                <Col sm={ 12 } className={ 'font-bold' }>
                  <h5 className={ 'mt-3' }>
                    Our simple Vibe<span className={ 'font-primary' }>^</span> software and unique survey process
                    delivers precise, real-time actionable people insights and recommendations, guiding leaders to focus
                    on what drives the greatest impact on goals.
                  </h5>
                </Col>
                <Col sm={ 12 } className={ 'mt-3' }>
                  <ul className={ 'ps-3' }>
                    <li><h5>Pinpoint why teams aren’t meeting targets</h5></li>
                    <li><h5>Identify productivity gaps and turnover risks early</h5></li>
                    <li><h5>Accurately assess engagement so small issues don’t become big problems</h5></li>
                  </ul>
                  <div>
                    <h5>among other scenarios</h5>
                  </div>
                </Col>
                <Col sm={ 12 }
                     className={ 'font-bold margin-top-100 justify-content-center d-flex flex-column text-center' }>
                  <div ref={ scheduleCallRef }>
                    <Button variant="primary" className="btn-block slide-left-right p-3" id="schedule-call-2"
                            onClick={ () => navigate('/contact') }>
                      <strong style={ {fontSize: '26px'} }>Schedule a call</strong>
                    </Button>
                  </div>
                  <span className={ 'font-normal mt-1 slide-left-right' }>Get actionable insights within 1 day of process start</span>
                </Col>
              </Row>
            </Col>
            <Col md={ 7 } sm={ 12 } className={'d-md-block d-none'}>
              <Image
                src={ kpi }
                className={ 'slide-right-left' }
              />
            </Col>
          </Row>
        </Container>
        <div className="wave-divider" id={ 'use-cases' }>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
            preserveAspectRatio="none"
          >
            <path
              fill="#f8f9fa"
              d="M0,192L48,213.3C96,235,192,277,288,266.7C384,256,480,192,576,186.7C672,181,768,235,864,240C960,245,1056,203,1152,176C1248,149,1344,139,1392,133.3L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
            />
          </svg>
        </div>
        <div className="wave-section">
          <Container>
            <Row>
              <Col md={ 12 } className={ 'text-start' }>
                <div style={ {fontSize: '48px'} }>
                  <strong>Turn on the lights with real-time insights</strong>
                </div>
              </Col>
              <Col md={ 12 } className={ 'pt-5 text-start' }>
                <div className="vertical-list">
                  <div className="line"></div>
                  { items.map((text, index) => (
                    <div className="vertical-list-item" key={ index }>
                      <div className='dot'></div>
                      <div className="text">{ text }</div>
                    </div>
                  )) }
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </section>
  );
}
